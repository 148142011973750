import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If, For, Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import settings from '../../../../content/settings/general.json'
import countries from '../../../../content/settings/countries.json'
import {media, sendForm, convertMDContent} from '../../../utils'
import Typography from '../../atoms/Typography'
import SubmitButton from '../../inputs/SubmitButton'
import TextInput from '../../inputs/TextInput'
import TextArea from '../../inputs/TextArea'
import PhoneInput from '../../inputs/PhoneInput'
import RadioButton from '../../inputs/RadioButton'


const StyledDescriptionContainer = styled.div`
  padding-top: 4.375rem;

  ${media.down('mobile')(css`
    padding: 3rem 0;
  `)}
`

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;

  ${media.down('mobile')(css`
    padding: 0;
  `)}
`

const StyledMessage = styled.span`
  opacity: 0.5;
  padding: 3rem 0 2.5rem 0;
  line-height: 3.75rem;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  width: 100%;

  ${media.down('mobile')(css`
    padding-top: 0;
  `)}
`

const StyledInputContainer = styled.div`
  padding-bottom: 1rem;
`

const StyledTitle = styled(Typography)`
  padding: 2rem 0;
`

const StyledRadioButtonsContainer = styled.div`
  display: flex;
  padding-bottom: 1rem;

  ${media.down('mobile')(css`
    flex-direction: column;
    padding-bottom: 0;
  `)}
`

const StyledRadioButtonContainer = styled.div`
  width: 11rem;
`

const StyledRadioButton = styled(RadioButton)`
  margin: 0 auto 1rem 0;

  ${media.down('mobile')(css`
    padding-left: 0;
    margin-bottom: 1.5rem;
  `)}
`

const StyledTextContainer = styled.div`
  padding-bottom: 2rem;

  a {
    text-decoration: underline;
    color: ${({theme}) => theme.colors.secondary};
  }
`

const StyledButton = styled(SubmitButton)`
  ${media.down('mobile')(css`
    height: 4.5rem;
  `)}
`

const ContactForm = ({lang, formContent, ...props}) => {
  const initialData = {
    name: '',
    email: '',
    prefix: countries.countries[56].dial_code,
    number: '',
    company: '',
    message: '',
    type: formContent.workType[0],
    budget: formContent.budget[0],
  }

  const {form} = settings[lang]
  const [formData, setFormData] = useState(initialData)
  const [showMessage, setShowMessage] = useState(false)
  const [showBudget, setShowBudget] = useState(formContent.workType[0])

  const handleChange = (name, value) => setFormData((prevValues) => ({...prevValues, [name]: value}))

  const handleVisibleBudget = (workType) => {
    setShowBudget(workType)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    await sendForm({'form-name': 'contact', ...formData})
      .then(() => {
        setShowMessage(true)
      })
      .catch((error) => console.error(error))
  }

  return (
    <>
      <StyledDescriptionContainer {...props}>
        <Typography>{formContent.description}</Typography>
      </StyledDescriptionContainer>
      <StyledFormContainer {...props}>
        <Choose>
          <When condition={showMessage}>
            <StyledMessage>{form.successMessage}</StyledMessage>
          </When>
          <Otherwise>
            <StyledForm
                name="contact"
                method="post"
                action="/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
            >
              <StyledInputContainer>
                <input type="hidden" name="form-name" value="contact" />
                <TextInput
                    name="name"
                    label={form.name}
                    value={formData?.name}
                    onChange={handleChange}
                    required
                />
              </StyledInputContainer>
              <StyledInputContainer>
                <input type="hidden" name="form-name" value="contact" />
                <TextInput
                    name="email"
                    type="email"
                    label={form.email}
                    value={formData?.email}
                    onChange={handleChange}
                    required
                />
              </StyledInputContainer>
              <StyledInputContainer>
                <input type="hidden" name="form-name" value="contact" />
                <PhoneInput
                    label={form.phoneNumber}
                    prefixName="prefix"
                    numberName="number"
                    onChange={handleChange}
                    NumberValue={formData?.number}
                    prefixValue={formData?.prefix}
                />
              </StyledInputContainer>
              <StyledInputContainer>
                <input type="hidden" name="form-name" value="contact" />
                <TextInput
                    name="company"
                    label={form.company}
                    value={formData?.company}
                    onChange={handleChange}
                />
              </StyledInputContainer>
              <StyledTitle variant="h3" type="h5">{formContent.workTypeText}</StyledTitle>
              <StyledRadioButtonsContainer>
                <input type="hidden" name="form-name" value="contact" />
                <For each="workType" of={formContent.workType}>
                  <StyledRadioButtonContainer key={workType}>
                    <StyledRadioButton
                        name="type"
                        value={workType}
                        onChange={(name, value) => {
                          handleChange(name, value)
                          handleVisibleBudget(workType)
                        }}
                        checked={formData.type === workType}
                    />
                  </StyledRadioButtonContainer>
                </For>
              </StyledRadioButtonsContainer>
              <If condition={showBudget === formContent.workType[0]}>
                <StyledTitle variant="h3" type="h5">{formContent.budgetText}</StyledTitle>
                <StyledRadioButtonsContainer>
                  <input type="hidden" name="form-name" value="contact" />
                  <For each="budget" of={formContent.budget}>
                    <StyledRadioButtonContainer key={budget}>
                      <StyledRadioButton
                          name="budget"
                          value={budget}
                          onChange={handleChange}
                          checked={formData.budget === budget}
                      />
                    </StyledRadioButtonContainer>
                  </For>
                </StyledRadioButtonsContainer>
              </If>
              <StyledTitle variant="h3" type="h5">{formContent.messageText}</StyledTitle>
              <StyledInputContainer>
                <input type="hidden" name="form-name" value="contact" />
                <TextArea
                    name="message"
                    label={form.message}
                    value={formData?.message}
                    onChange={handleChange}
                />
              </StyledInputContainer>
              <StyledTextContainer>
                <Typography thin dangerouslySetInnerHTML={{__html: convertMDContent(form.confirmation)}} />
              </StyledTextContainer>
              <StyledInputContainer>
                <StyledButton value={form.confirmButton} />
              </StyledInputContainer>
            </StyledForm>
          </Otherwise>
        </Choose>
      </StyledFormContainer>
    </>

  )
}

ContactForm.propTypes = {
  lang: PropTypes.string.isRequired,
  formContent: PropTypes.object.isRequired,
}

export default ContactForm
